import { Input, Space, Table, Tag } from 'antd';
import React, { useState, useRef, useEffect } from 'react';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import localeObjectTable from '../../Utils/localeObjectTable';
import PropTypes from 'prop-types';
import StatusBar from './StatusBar';
import Boton from '../Atoms/Boton';
import currencyFormatter from '../../Utils/currencyFormatter';
import { DatePicker } from 'antd';
import moment from 'moment';
import es from 'antd/lib/date-picker/locale/es_ES';
import decodeToken from '../../Utils/decodeToken';
import { Link } from 'react-router-dom';

const TablaSolicitudesCompra = ({ data, loading, unnamed }) => {
  const { RangePicker } = DatePicker;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  let decodedToken = decodeToken(localStorage.getItem('token'));
  const userRole = decodedToken?.role?.name;

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    // Guardar el filtro aplicado en localStorage
    const savedFilters = JSON.parse(localStorage.getItem('tableFilters')) || {};
    savedFilters[dataIndex] = selectedKeys[0];
    localStorage.setItem('tableFilters', JSON.stringify(savedFilters));
  };

  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem('tableFilters'));
    if (savedFilters && savedFilters['user_name']) {
      // Si existe un filtro guardado para 'user_name', aplícalo automáticamente
      setSearchText(savedFilters['user_name']);
      setSearchedColumn('user_name');
    }
  }, []);

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
    // Eliminar el filtro de 'user_name' de localStorage
    const savedFilters = JSON.parse(localStorage.getItem('tableFilters')) || {};
    delete savedFilters['user_name'];
    localStorage.setItem('tableFilters', JSON.stringify(savedFilters));
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: '0 8px 8px 8px'
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Boton
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
          className="button-close-search-filter-table">
          X
        </Boton>
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Space>
          <Boton
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small">
            Buscar
          </Boton>
          <Boton onClick={() => clearFilters && handleReset(clearFilters)} size="small" danger>
            Limpiar
          </Boton>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  });

  const newDataSOL = data?.map((item) => {
    return {
      key: item?.id,
      id: item?.id,
      created_at: item?.created_at,
      creation_date: item?.creation_date,
      approved_by: item?.approved_by ? item?.approved_by : 'PENDIENTE',
      admin_approved: item?.admin_approved ? item?.admin_approved : 'PENDIENTE',
      total: item?.total,
      user_name: item?.user_name,
      purchase_table_name: item?.purchase_table_name,
      type_status_request: item?.type_status,
      info_status: (
        <StatusBar
          status={item?.purchase_table_name}
          substatus={item?.type_status}
          approved={item?.approved_status}
        />
      ),
      verdetalle: (
        <Boton type="secondary" icon={<PlusOutlined />}>
          Ver Detalle
        </Boton>
      )
    };
  });

  const tableSolicitudes = [
    {
      title: 'ID SOLICITUD',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      filteredValue: searchText && searchedColumn === 'id' ? [searchText] : null,
      ...getColumnSearchProps('id'),
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['ascend', 'descend'],
      render: (text) => <Link to={`/detalle-soliciutd-${text}`}>GTO-{text}</Link>
    },
    [
      'administrator',
      'Super_Admin',
      'caja_chica',
      'adquisiciones_bh',
      'adquisiciones_pl',
      'manager'
    ].includes(userRole) && !unnamed
      ? {
          title: 'NOMBRE SOLICITANTE',
          dataIndex: 'user_name',
          key: 'user_name',
          ...getColumnSearchProps('user_name'),
          sorter: (a, b) => a.user_name.localeCompare(b.user_name),
          sortDirections: ['ascend', 'descend'],
          filteredValue: searchText && searchedColumn === 'user_name' ? [searchText] : null
        }
      : null,
    {
      title: 'ESTADO',
      dataIndex: 'info_status',
      key: 'info_status',
      filteredValue: searchText && searchedColumn === 'purchase_table_name' ? [searchText] : null,
      filters: [
        {
          text: 'En proceso',
          value: 'pedido-en-proceso'
        },
        {
          text: 'Adquisición',
          value: 'pedido-en-compra'
        },
        {
          text: 'Entregado',
          value: 'pedido-entregado'
        },
        {
          text: 'Recibido',
          value: 'pedido-recibido'
        },
        {
          text: 'Devolución',
          value: 'pedido-devolución'
        }
      ],
      onFilter: (value, record) => record.purchase_table_name.indexOf(value) === 0,
      width: 'auto'
    },
    {
      title: 'AUTORIZACIÓN',
      dataIndex: 'approval',
      key: 'approval',
      filteredValue: searchText && searchedColumn === 'approved_by' ? [searchText] : null,
      render: (text, record) => renderApprovalTags(record.approved_by, record.admin_approved)
    },
    {
      title: 'TOTAL',
      dataIndex: 'total',
      key: 'total',
      filteredValue: searchText && searchedColumn === 'total' ? [searchText] : null,
      ...getColumnSearchProps('total'),
      sorter: (a, b) => a.total - b.total,
      sortDirections: ['ascend', 'descend'],
      render: (text) => currencyFormatter(text)
    },
    {
      title: 'FECHA DE CREACIÓN',
      dataIndex: 'created_at',
      key: 'created_at',
      filteredValue: searchText && searchedColumn === 'created_at' ? [searchText] : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
          style={{
            padding: '0 8px 8px 8px'
          }}
          onKeyDown={(e) => e.stopPropagation()}>
          <Boton
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            className="button-close-search-filter-table">
            X
          </Boton>
          <RangePicker
            locale={es}
            placeholder={['Fecha inicial', 'Fecha final']}
            value={selectedKeys[0]}
            onChange={(dates) => {
              setSelectedKeys([dates]);
            }}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Boton
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small">
            Buscar
          </Boton>
          <Boton onClick={clearFilters} size="small" danger>
            Limpiar
          </Boton>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? '#1890ff' : undefined
          }}
        />
      ),
      onFilter: (value, record) => {
        if (!value) return true;
        const start = value[0].startOf('day').toDate(); // Convertir a objeto Date
        const end = value[1].endOf('day').toDate(); // Convertir a objeto Date
        const created = moment(record.created_at, 'DD-MM-YYYY').toDate(); // Convertir a objeto Date
        return created >= start && created <= end;
      },
      sorter: (a, b) => a.created_at - b.created_at,
      sortDirections: ['ascend', 'descend']
    }
  ].filter(Boolean);

  const renderApprovalTags = (approvedBy, adminApproved) => {
    return (
      <div>
        {approvedBy && (
          <Tag color={approvedBy === 'PENDIENTE' ? 'blue' : 'success'}>
            JEFE DIRECTO {'-> '}
            <strong>{approvedBy}</strong>
          </Tag>
        )}
        {adminApproved && (
          <Tag color={adminApproved === 'PENDIENTE' ? 'blue' : 'success'} className="mt-3">
            ADMINISTRADOR {'-> '}
            <strong>{adminApproved}</strong>
          </Tag>
        )}
      </div>
    );
  };

  return (
    <>
      <Table
        loading={loading}
        columns={tableSolicitudes}
        dataSource={newDataSOL}
        pagination={{
          position: ['bottomCenter'],
          defaultPageSize: 20,
          pageSizeOptions: [20, 40, 60, 80, 100],
          total: data?.total,
          showTotal: (total, range) =>
            `Mostrando solicitudes del ${range[0]} al ${range[1]} de un total de ${total}`
        }}
        className="mt-4 mb-4"
        locale={localeObjectTable}
        scroll={{
          x: true
        }}
      />
    </>
  );
};

TablaSolicitudesCompra.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  unnamed: PropTypes.bool
};
export default TablaSolicitudesCompra;
