import React, { useEffect, useState } from 'react';
import { Form, Input, InputNumber, Upload, Row, Col, Alert } from 'antd';
const { TextArea } = Input;
import {
  ArrowLeftOutlined,
  PaperClipOutlined,
  MinusCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import Boton from '../Components/Atoms/Boton';
import { useNavigate } from 'react-router-dom';
import decodeToken from '../Utils/decodeToken.jsx';
import { Select, TreeSelect } from 'antd';
import clienteAxios from '../Utils/clienteAxios';
import Swal from 'sweetalert2';
import rolessg from '../rolessg.js';
import { sweetError } from '../Utils/sweetError.jsx';

const NuevaSolicitud = () => {
  const [archive, setArchive] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isFileRemoved, setIsFileRemoved] = useState(false);
  const [isEventualesVisible, setIsEventualesVisible] = useState(false);
  const [total, setTotal] = useState(0);
  const [eventuales, setEventuales] = useState([]);
  const [companyEventual, setCompanyEventual] = useState([]);
  const navigate = useNavigate();
  const [val, setVal] = useState();
  const [newConcepts, setNewConcepts] = useState([]);
  const [spentNewId, setSpentNewId] = useState('');
  const [specificId, setSpecificId] = useState('');
  const [loading, setLoading] = useState(true);
  const decodedToken = decodeToken(localStorage.getItem('token'));
  const userRole = decodedToken?.role?.name;
  const [noFile, setNoFile] = useState(false);

  const onChangeselec = (newValue) => {
    if (newValue) {
      const [newSpentNewId, newSpecificId] = newValue.split('-').slice(1);
      setSpentNewId(newSpentNewId);
      setSpecificId(newSpecificId);
      setVal(newValue);

      const isCCOrGenOption =
        newOptionsCC.some(
          (option) =>
            option.value === newValue || option.children.some((child) => child.value === newValue)
        ) ||
        newOptionsGen.some(
          (option) =>
            option.value === newValue || option.children.some((child) => child.value === newValue)
        );
      isCCOrGenOption && !archive ? setNoFile(true) : setNoFile(false);
      const isEventuales =
        newOptionsCC.some(
          (option) =>
            option.label.includes('Eventuales', 'Eventuales Fin de Semana') ||
            option.children.some(
              (child) =>
                child.label.includes('Eventuales', 'Eventuales Fin de Semana') &&
                child.value === newValue
            )
        ) ||
        newOptionsGen.some(
          (option) =>
            option.label.includes('Eventuales', 'Eventuales Fin de Semana') ||
            option.children.some(
              (child) =>
                child.label.includes('Eventuales', 'Eventuales Fin de Semana') &&
                child.value === newValue
            )
        );

      setIsEventualesVisible(isEventuales);
    } else {
      setSpentNewId(undefined);
      setSpecificId(undefined);
      setVal('');
      setIsEventualesVisible(false); // Reset to false if no value is selected
    }
  };

  // API PARA OBTENER LAS EMPRESAS EVENTUALES
  useEffect(() => {
    clienteAxios
      .get(`/sg/company-eventual`)
      .then((response) => {
        let filteredData = response?.data?.companies.filter((item) => item.status === '1');
        setCompanyEventual(
          filteredData.map((item) => ({
            value: item.id,
            label: item.name
          }))
        );
      })
      .catch((error) => {
        sweetError(error);
      });
  }, []);

  // API PARA OBTENER LOS CONCEPTOS
  useEffect(() => {
    clienteAxios
      .get(`/sg/view-concepts-restrictions`)
      .then((response) => {
        setNewConcepts(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        sweetError(error);
      });
  }, []);

  //API PARA ENVIAR FORMULARIO DE LA SOLICITUD
  const enviarFormulario = (values) => {
    const { company_id, spent_id, description, type, total, users } = values;
    const data = new FormData();
    const EventualsExist = isEventualesVisible && users && users.length > 0;
    if (EventualsExist) {
      const eventualIncomplete = users.some((user) => !user.name || !user.pay);
      if (eventualIncomplete) {
        Swal.fire({
          icon: 'error',
          title: 'Error al crear la solicitud',
          text: 'Completa todos los campos de los usuarios eventuales',
          confirmButtonText: 'Cerrar'
        });
        return;
      }
      const totalFromInput = users.reduce((acc, user) => acc + parseFloat(user.pay || 0), 0);
      data.append('total', totalFromInput);
      users.forEach((user, index) => {
        data.append(`eventuales[${index}][name]`, user.name);
        data.append(`eventuales[${index}][pay]`, user.pay);
        data.append(`eventuales[${index}][company]`, user.company);
      });
    } else {
      data.append('total', total);
    }
    if (!isFileRemoved) {
      data.append('file', archive);
    }
    data.append('company_id', company_id);
    data.append('spent_id', spent_id);
    data.append('description', description);
    data.append('type', type);
    data.append('spent_new_id', spentNewId);
    data.append('specific_id', specificId);
    setIsButtonDisabled(true);
    clienteAxios
      .post(`/sg/create-request`, data)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: '¡Creación de solicitud exitosa!',
          showCancelButton: false,
          confirmButtonText: 'LISTO'
        }).then(() => {
          navigate('/solicitudes-gasto');
        });
      })
      .catch((error) => {
        console.error(error);
        setIsButtonDisabled(false);
        sweetError(error);
      });
  };

  const newOptionsCC = newConcepts?.conceptos_caja_chica?.map((item) => {
    return {
      key: `cc-${item?.id_spent}`,
      code: item?.code,
      value: `cc-${item?.id_spent}`,
      label: item?.code + ' - ' + item?.general_concept,
      searchLabel: '0001-' + item?.code,
      disabled: true,
      children:
        item?.conceptos_especificos?.map((specific) => {
          return {
            key: `cc-${item?.id_spent}-${specific?.id}`,
            code: specific?.code_specific,
            value: `cc-${item?.id_spent}-${specific?.id}`,
            label: specific?.code_specific + ' - ' + specific?.specific_concept,
            searchLabel: '0001-' + item?.code + '-' + specific?.code_specific,
            style: { color: 'var(--secondary-color-green)' }
          };
        }) || []
    };
  });

  const newOptionsAmex = newConcepts?.conceptos_amex?.map((item) => {
    return {
      key: `am-${item?.id_spent}`,
      code: item?.code,
      value: `am-${item?.id_spent}`,
      label: item?.code + ' - ' + item?.general_concept,
      searchLabel: '0002-' + item?.code,
      disabled: true,
      children:
        item?.conceptos_especificos?.map((specific) => {
          return {
            key: `am-${item?.id_spent}-${specific?.id}`,
            code: specific?.code_specific,
            value: `am-${item?.id_spent}-${specific?.id}`,
            label: specific?.code_specific + ' - ' + specific?.specific_concept,
            searchLabel: '0002-' + item?.code + '-' + specific?.code_specific,
            style: { color: 'var(--status-text-warning-whiter)' }
          };
        }) || []
    };
  });

  const newOptionsGen = newConcepts?.conceptosusercc?.map((item) => {
    return {
      key: `gn-${item?.id_spent}`,
      code: item?.code,
      value: `gn-${item?.id_spent}`,
      label: item?.code + ' - ' + item?.general_concept,
      disabled: true,
      children:
        item?.conceptos_especificos?.map((specific) => {
          return {
            key: `gn-${item?.id_spent}-${specific?.id}`,
            code: specific?.code_specific,
            value: `gn-${item?.id_spent}-${specific?.id}`,
            label: specific?.code_specific + ' - ' + specific?.specific_concept,
            searchLabel: '0003-' + item?.code + '-' + specific?.code_specific,
            style: { color: 'purple' }
          };
        }) || []
    };
  });

  const dataPaymets = [
    ...(['administrator', 'Super_Admin', 'caja_chica'].includes(userRole)
      ? [
          {
            title: '0001 - Caja Chica',
            value: '0001',
            disabled: true,
            style: { color: 'var(--secondary-color-green)' },
            children: newOptionsCC
          }
        ]
      : []),
    ...(['administrator', 'Super_Admin', 'adquisiciones_pl', 'adquisiciones_bh'].includes(userRole)
      ? [
          {
            title: '0002 - Amex',
            value: '0002',
            disabled: true,
            style: { color: 'var(--status-text-warning-whiter)' },
            children: newOptionsAmex
          }
        ]
      : []),
    ...(['administrator', 'Super_Admin'].includes(userRole) || rolessg.includes(userRole)
      ? [
          {
            title: '0003 - Usuarios',
            value: '0003',
            disabled: true,
            style: { color: 'purple' },
            children: newOptionsGen
          }
        ]
      : [])
  ];

  return (
    <>
      <h1 className="title-arrow mb-4 mr-10">
        <ArrowLeftOutlined className="arrow-size" onClick={() => navigate('/solicitudes-gasto')} />
        Nueva Solicitud
      </h1>

      {noFile ? (
        <Alert
          message="Debes anexar un comprobante; de lo contrario, no podrás generar tu solicitud. 
          Si el comprobante no es legible o la descripción no es clara, tu solicitud será rechazada."
          type="warning"
          showIcon
          closable
          className="mb-4"
        />
      ) : null}

      <Form
        className="bold-form"
        onFinish={enviarFormulario}
        labelCol={{
          span: 8
        }}
        labelAlign="left"
        wrapperCol={{
          span: 8
        }}
        encType="multipart/form-data"
        layout="horizontal">
        <Form.Item label="Nombre Completo">
          <Input disabled value={decodedToken?.user?.name} />
        </Form.Item>

        <Form.Item label="Concepto">
          <TreeSelect
            disabled={loading}
            placeholder={loading ? 'Cargando...' : 'Selecciona un concepto'}
            showSearch
            allowClear
            treeDefaultExpandAll={false}
            value={val}
            onChange={onChangeselec}
            treeData={dataPaymets}
            filterTreeNode={(inputValue, treeNode) => {
              const label = (treeNode.label || treeNode.title)?.toLowerCase() || '';
              const searchLabel = (treeNode.searchLabel || '')?.toLowerCase();
              return (
                label.includes(inputValue.toLowerCase()) ||
                searchLabel.includes(inputValue.toLowerCase())
              );
            }}
          />
        </Form.Item>

        {isEventualesVisible && (
          <>
            <Form.Item required label="Eventuales">
              <Form.List name="users">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row key={key} gutter={8}>
                        <Col span={22}>
                          <Form.Item
                            {...restField}
                            name={[name, 'name']}
                            rules={[
                              {
                                required: true,
                                message: 'Escribe el nombre del eventual'
                              }
                            ]}>
                            <Input placeholder="Nombre" />
                          </Form.Item>
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            {...restField}
                            name={[name, 'company']}
                            rules={[
                              {
                                required: true,
                                message: 'Selecciona una empresa!'
                              }
                            ]}>
                            <Select
                              placeholder="Empresa"
                              options={companyEventual}
                              required
                              allowClear
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            {...restField}
                            name={[name, 'pay']}
                            rules={[
                              { required: true, message: 'Escribe un monto!' },
                              { type: 'number', min: 1, message: 'El monto debe ser mayor que 0' }
                            ]}>
                            <InputNumber
                              formatter={(value) =>
                                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                              }
                              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                              onChange={(value) => {
                                const updatedEventuales = [...eventuales];
                                updatedEventuales[name] = value;
                                setEventuales(updatedEventuales);
                                setTotal(
                                  updatedEventuales.reduce((acc, curr) => acc + (curr || 0), 0)
                                );
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <MinusCircleOutlined
                            className="mt-2"
                            onClick={() => {
                              remove(name);
                              const updatedEventuales = [...eventuales];
                              updatedEventuales.splice(name, 1);
                              setEventuales(updatedEventuales);
                              setTotal(
                                updatedEventuales.reduce((acc, curr) => acc + (curr || 0), 0)
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    ))}
                    <Form.Item>
                      <Boton
                        color="primary"
                        variant="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                        required>
                        Agregar Eventual
                      </Boton>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form.Item>
          </>
        )}
        {isEventualesVisible ? (
          <Form.Item label="Importe">
            <Input disabled value={`$ ${total}`} />
          </Form.Item>
        ) : (
          <Form.Item
            rules={[
              { required: true, message: 'Escribe un monto!' },
              { type: 'number', min: 1, message: 'El monto debe ser mayor que 0' }
            ]}
            name="total"
            label="Importe">
            <InputNumber
              disabled={isEventualesVisible}
              label={1000}
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              value={isEventualesVisible ? total : null}
            />
          </Form.Item>
        )}
        <Form.Item
          rules={[{ required: true, message: 'Escribe una breve descripción o anexa tú link!' }]}
          name="description"
          label="Descripción de la necesidad">
          <TextArea rows={8} showCount maxLength={500} placeholder="Escribe aquí" />
        </Form.Item>
        <Form.Item>
          <Upload
            maxCount={1}
            listType="picture"
            accept=".pdf, .jpg, .png, .jpeg, .doc, .docx, .xls, .xlsx"
            beforeUpload={(file) => {
              setArchive(file);
              setIsFileRemoved(false);
              setNoFile(false);
              return false;
            }}
            onRemove={() => {
              setArchive(null);
              setIsFileRemoved(true);
              const isCCOrGenOption = val?.includes('cc') || val?.includes('gn');
              isCCOrGenOption ? setNoFile(true) : setNoFile(false);
            }}
            multiple={false}>
            <Boton color="primary" variant="outlined" icon={<PaperClipOutlined />}>
              Adjuntar archivo o imagen
            </Boton>
          </Upload>
        </Form.Item>
        <div className="justify-content-end">
          <Boton type="primary" htmlType="submit" loading={isButtonDisabled} disabled={noFile}>
            Generar Solicitud
          </Boton>
        </div>
      </Form>
    </>
  );
};
export default NuevaSolicitud;
