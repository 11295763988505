import React, { useEffect, useState } from 'react';
import TablaEmpresas from '../Components/Organisms/TablaEmpresas';
import { ArrowLeftOutlined, IdcardOutlined } from '@ant-design/icons';
import clienteAxios from '../Utils/clienteAxios';
import { useNavigate } from 'react-router-dom';
import Boton from '../Components/Atoms/Boton';
import { Form, Input } from 'antd';
import Swal from 'sweetalert2';
import Modal from '../Components/Atoms/Modal';
import { sweetError } from '../Utils/sweetError.jsx';

const HistorialEmpresasEventuales = () => {
  const [historyCompanies, setHistoryCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reloadAddCompany, setReloadAddCompany] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    clienteAxios
      .get(`/sg/company-eventual`)
      .then((response) => {
        setHistoryCompanies(response?.data?.companies.reverse());
        setLoading(false);
      })
      .catch((error) => {
        sweetError(error);
      });
  }, [loading]);

  const handleOk = () => {
    let existe = historyCompanies.find((empresa) => empresa.name === companyName);
    if (existe) {
      Swal.fire({
        icon: 'error',
        title: 'Cuidado!',
        text: 'La empresa ya existe',
        showCancelButton: false,
        confirmButtonText: 'ACEPTAR'
      });
      return;
    }
    setIsSubmitting(true);
    clienteAxios
      .post(`/sg/new-company-eventual`, { name: companyName })
      .then(() => {
        setIsSubmitting(false);
        handleCancel();
        setReloadAddCompany(!reloadAddCompany);
        Swal.fire({
          icon: 'success',
          title: 'Empresa agregada correctamente!',
          showCancelButton: false,
          confirmButtonText: 'LISTO'
        }).then(() => {
          setLoading(true);
        });
      })
      .catch((error) => {
        console.error(error);
        setIsSubmitting(false);
        sweetError(error);
      });
  };

  return (
    <>
      <h1 className="title-arrow mb-3">
        <ArrowLeftOutlined className="arrow-size" onClick={() => navigate('/reporte-general')} />
        EMPRESAS EVENTUALES
      </h1>
      <div className="justify-content-end">
        <Boton
          className="mr-button"
          color="primary"
          variant="outlined"
          icon={<IdcardOutlined />}
          onClick={showModal}>
          Agregar Empresa
        </Boton>
      </div>

      <TablaEmpresas data={historyCompanies} setLoading={setLoading} loading={loading} />

      <Modal
        title="AGREGAR EMPRESA"
        centered
        key={reloadAddCompany}
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        confirmLoading={isSubmitting}>
        <Form className="mt-4">
          <Form.Item
            rules={[{ required: true, message: 'Por favor ingrese el nombre de la empresa' }]}
            name="nombre_empresa"
            label="Nombre de la Empresa"
            onChange={(e) => setCompanyName(e.target.value)}>
            <Input type="text" placeholder="Nombre de la Empresa" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default HistorialEmpresasEventuales;
