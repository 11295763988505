import React from 'react';
import { Form, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import Modal from '../../Components/Atoms/Modal';

export default function ModalActualizarMonto({
  isModalOpenAmount,
  showModalAmount,
  handleUpdatePayment,
  setAmountUpdate
}) {
  return (
    <>
      <Modal
        title="ACTUALIZAR MONTO"
        centered
        width={410}
        open={isModalOpenAmount}
        onCancel={showModalAmount}
        onOk={handleUpdatePayment}>
        <Form className="mt-4">
          <Form.Item label="Importe">
            <InputNumber
              className="width-100"
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              onChange={(value) => setAmountUpdate(value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

ModalActualizarMonto.propTypes = {
  isModalOpenAmount: PropTypes.bool,
  showModalAmount: PropTypes.func,
  handleUpdatePayment: PropTypes.func,
  setAmountUpdate: PropTypes.func
};
