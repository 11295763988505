import React from 'react';
import { Table, Tag } from 'antd';
import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import clienteAxios from '../../Utils/clienteAxios';
import Boton from '../Atoms/Boton';
import localeObjectTable from '../../Utils/localeObjectTable';
import { sweetError } from '../../Utils/sweetError';

export const TablaCatalogo = ({ data, loading, setLoading }) => {
  const transformedData = data.map((item) => {
    const specificConcepts =
      item?.conceptos_especificos_caja_chica || item?.conceptos_especificos_amex || [];
    return {
      key: item?.id_spent,
      code: item?.code,
      general_concept: item?.general_concept,
      status: item?.status,
      updated_at: item?.updated_at,
      actions: (
        <>
          {item?.status === 0 ? (
            <Boton
              color="primary"
              variant="filled"
              shape="circle"
              icon={<CheckCircleOutlined />}
              onClick={() => handleActivateGeneralConcept(item?.id_spent)}
            />
          ) : item?.status === 1 ? (
            <Boton
              color="danger"
              variant="filled"
              shape="circle"
              icon={<StopOutlined />}
              onClick={() => handleDeactivateGeneralConcept(item?.id_spent)}
            />
          ) : null}
        </>
      ),
      specific_concepts: specificConcepts.map((concept) => ({
        key: concept?.id_specific,
        id: concept?.id_specific,
        specific_concept: concept?.specific_concept,
        code_specific: concept?.code_specific,
        status_especific: concept?.status,
        // category: concept?.category,
        // type_of_expense: concept?.type_of_expense,
        // type_of_acquisition: concept?.type_of_acquisition,
        created_at: concept?.created_at,
        actions_es: (
          <>
            {concept?.status === 0 ? (
              <Boton
                color="primary"
                variant="filled"
                shape="circle"
                onClick={() => handleActivateEspecificConcept(concept?.id_specific)}
                icon={<CheckCircleOutlined />}
              />
            ) : concept?.status === 1 ? (
              <Boton
                color="danger"
                variant="filled"
                shape="circle"
                icon={<StopOutlined />}
                onClick={() => handleDeactivateEspecificConcept(concept?.id_specific)}
              />
            ) : null}
          </>
        )
      }))
    };
  });

  const columns = [
    {
      title: 'CÓDIGO',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'CONCEPTO GENERAL',
      dataIndex: 'general_concept',
      key: 'general_concept'
    },
    {
      title: 'ESTADO',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return status === 1 ? (
          <Tag color="geekblue" key={status}>
            Activado
          </Tag>
        ) : status === 0 ? (
          <Tag color="red" key={status}>
            Desactivado
          </Tag>
        ) : null;
      }
    },
    {
      title: 'FECHA DE CREACIÓN',
      dataIndex: 'updated_at',
      key: 'updated_at'
    },
    {
      title: 'ACCIONES',
      dataIndex: 'actions',
      key: 'actions'
    }
  ];

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: 'CÓDIGO',
        dataIndex: 'code_specific',
        key: 'code_specific'
      },
      {
        title: 'CONCEPTO ESPECÍFICO',
        dataIndex: 'specific_concept',
        key: 'specific_concept'
      },
      // {
      //   title: 'CATEGORÍA',
      //   dataIndex: 'category',
      //   key: 'category'
      // },
      // {
      //   title: 'TIPO DE GASTO',
      //   dataIndex: 'type_of_expense',
      //   key: 'type_of_expense'
      // },
      // {
      //   title: 'TIPO DE ADQUISICIÓN',
      //   dataIndex: 'type_of_acquisition',
      //   key: 'type_of_acquisition'
      // },
      {
        title: 'ESTADO',
        dataIndex: 'status_especific',
        key: 'status_especific',
        render: (status_especific) => {
          return status_especific === 1 ? (
            <Tag color="geekblue" key={status_especific}>
              Activado
            </Tag>
          ) : status_especific === 0 ? (
            <Tag color="red" key={status_especific}>
              Desactivado
            </Tag>
          ) : null;
        }
      },
      {
        title: 'FECHA DE CREACIÓN',
        dataIndex: 'created_at',
        key: 'created_at'
      },
      {
        title: 'ACCIONES',
        dataIndex: 'actions_es',
        key: 'actions_es'
      }
    ];

    return (
      <Table
        className="detalles-general"
        columns={columns}
        dataSource={record.specific_concepts}
        pagination={false}
      />
    );
  };

  const handleActivateGeneralConcept = (id) => {
    Swal.fire({
      title: 'Activar concepto general',
      text: '¿Estás seguro de activarlo?',
      icon: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: '¡Sí, activar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        clienteAxios
          .post(`/sg/activate-general-concept`, { id: id })
          .then((response) =>
            Swal.fire({
              icon: 'success',
              title: '¡Activado!',
              text: response.data.message,
              confirmButtonText: 'Aceptar'
            }).then(() => {
              setLoading(true);
            })
          )
          .catch((error) => {
            sweetError(error);
          });
      }
    });
  };

  const handleDeactivateGeneralConcept = (id) => {
    console.log(id);
    Swal.fire({
      title: 'Desactivar concepto general',
      text: '¿Estás seguro de desactivarlo?',
      icon: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: '¡Sí, desactivar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        clienteAxios
          .post(`/sg/deactivate-general-concept`, { id: id })
          .then((response) =>
            Swal.fire({
              icon: 'success',
              title: '¡Desactivado!',
              text: response.data.message,
              confirmButtonText: 'Aceptar'
            }).then(() => {
              setLoading(true);
            })
          )
          .catch((error) => {
            sweetError(error);
          });
      }
    });
  };

  const handleActivateEspecificConcept = (id) => {
    Swal.fire({
      title: 'Activar concepto específico',
      text: '¿Estás seguro de activarlo?',
      icon: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: '¡Sí, activar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        clienteAxios
          .post(`/sg/activate-concept-specific`, { id_specific_concept: id })
          .then((response) =>
            Swal.fire({
              icon: 'success',
              title: '¡Activado!',
              text: response.data.message,
              confirmButtonText: 'Aceptar'
            }).then(() => {
              setLoading(true);
            })
          )
          .catch((error) => {
            sweetError(error);
          });
      }
    });
  };

  const handleDeactivateEspecificConcept = (id) => {
    Swal.fire({
      title: 'Desactivar concepto específico',
      text: '¿Estás seguro de desactivarlo?',
      icon: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: '¡Sí, desactivar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        clienteAxios
          .post(`sg/deactivate-concept-specific`, { id_specific_concept: id })
          .then((response) =>
            Swal.fire({
              icon: 'success',
              title: '¡Desactivado!',
              text: response.data.message,
              confirmButtonText: 'Aceptar'
            }).then(() => {
              setLoading(true);
            })
          )
          .catch((error) => {
            sweetError(error);
          });
      }
    });
  };

  return (
    <>
      {/* el expand se muestre solo si existe data */}
      <Table
        loading={loading}
        columns={columns}
        dataSource={transformedData}
        expandable={{
          expandedRowRender: expandedRowRender
        }}
        locale={localeObjectTable}
        pagination={{
          position: ['bottomCenter']
        }}
      />
    </>
  );
};

TablaCatalogo.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  setLoading: PropTypes.func
};
