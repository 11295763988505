import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, ReadOutlined, BookOutlined, DownOutlined } from '@ant-design/icons';
import clienteAxios from '../Utils/clienteAxios';
import { useNavigate } from 'react-router-dom';
import Boton from '../Components/Atoms/Boton';
import { Form, Input, Select, Radio, TreeSelect, Dropdown } from 'antd';
import Modal from '../Components/Atoms/Modal';
import Swal from 'sweetalert2';
import { TablaCatalogo } from '../Components/Organisms/TablaCatalogo';
import { sweetError } from '../Utils/sweetError';

const HistorialConceptos = () => {
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [optionPayment, setOptionPayment] = useState('cc');
  const [isModalOpenGeneral, setIsModalOpenGeneral] = useState(false);
  const [companyNameGeneral, setCompanyNameGeneral] = useState('');
  const [selectedSpendingGeneral, setSelectedSpendingGeneral] = useState(null);
  const [conceptoCajaChica, setConceptoCajaChica] = useState([]);
  const [conceptoAmex, setConceptoAmex] = useState([]);
  const [specificConcept, setSpecificConcept] = useState('');
  // const [category, setCategory] = useState('');
  // const [typeOfExpense, setTypeOfExpense] = useState('');
  // const [typeOfAcquisition, setTypeOfAcquisition] = useState('');
  const [val, setVal] = useState();
  const [optionPayConcept, setOptionPayConcept] = useState([]);

  const onChangeselec = (newValue) => {
    setVal(newValue);
  };

  const handleOptionPayment = (e) => {
    setOptionPayment(e.target.value);
  };

  const OptionPayment = [
    { value: 1, label: '0001 - CAJA CHICA', key: 1 },
    { value: 2, label: '0002 - AMERICAN EXPRESS', key: 2 }
  ];

  // const OptionCategory = [
  //   { value: 1, label: 'Variable', key: 1 },
  //   { value: 2, label: 'Fijo', key: 2 }
  // ];

  // const OptionGCI = [
  //   { value: 1, label: 'Gasto', key: 1 },
  //   { value: 2, label: 'Costo', key: 2 },
  //   { value: 3, label: 'Inversión', key: 3 }
  // ];

  // const OptionAcquisition = [
  //   { value: 1, label: 'Servicio', key: 1 },
  //   { value: 2, label: 'Producto', key: 2 }
  // ];

  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModalGeneral = () => {
    setIsModalOpenGeneral(!isModalOpenGeneral);
  };

  //Traer informacion general de los conceptos para mostrar en la tabla AMEX / CAJA CHICA
  useEffect(() => {
    clienteAxios
      .get(`/sg/view-concepts`)
      .then((response) => {
        setConceptoCajaChica(response?.data?.Spents_CajaChica);
        setConceptoAmex(response?.data?.Spents_Amex);
        setLoading(false);
      })
      .catch((error) => {
        sweetError(error);
      });
  }, [loading]);

  // Traer conceptos generales para opciones
  useEffect(() => {
    clienteAxios
      .get(`/sg/concepts-restrictions`)
      .then((response) => {
        setOptionPayConcept(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        sweetError(error);
      });
  }, [loading]);

  //Agregar concepto general
  const handleAddConceptGeneral = () => {
    clienteAxios
      .post(`/sg/add-general-concept`, {
        general_concept: companyNameGeneral,
        amex_or_cajachica: selectedSpendingGeneral
      })
      .then(() => {
        showModalGeneral();
        Swal.fire({
          icon: 'success',
          title: 'Concepto agregado correctamente!',
          showCancelButton: false,
          confirmButtonText: 'LISTO'
        }).then(() => {
          setLoading(true);
          setCompanyNameGeneral('');
          setSelectedSpendingGeneral(null);
        });
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: 'error',
          title: 'Error al agregar el concepto!',
          showCancelButton: false,
          confirmButtonText: 'INTENTAR DE NUEVO'
        });
      });
  };

  //Agregar concepto especifico
  const handleAddConceptEspecifico = () => {
    clienteAxios
      .post(`/sg/add-specific-concept`, {
        specific_concept: specificConcept,
        id_new_spents: val
        // category: category,
        // type_of_expense: typeOfExpense,
        // type_of_acquisition: typeOfAcquisition
      })
      .then(() => {
        showModal();
        Swal.fire({
          icon: 'success',
          title: 'Concepto agregado correctamente!',
          showCancelButton: false,
          confirmButtonText: 'LISTO'
        }).then(() => {
          setLoading(true);
          setSpecificConcept('');
        });
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: 'error',
          title: 'Error al agregar el concepto!',
          showCancelButton: false,
          confirmButtonText: 'INTENTAR DE NUEVO'
        });
      });
  };

  const items = [
    {
      key: 1,
      label: (
        <Boton type="link" icon={<BookOutlined />} onClick={showModalGeneral} className="width-100">
          General
        </Boton>
      )
    },
    {
      key: 2,
      label: (
        <Boton type="link" icon={<ReadOutlined />} onClick={showModal} className="width-100">
          Específico
        </Boton>
      )
    }
  ];

  const newOptionsPayConcept = optionPayConcept?.Specific_caja_chica?.map((item) => {
    return {
      key: item?.id_spent,
      code: item?.code,
      value: item?.id_spent,
      label: item?.code + ' - ' + item?.general_concept, // Solo el código y concepto del hijo
      searchLabel: '0001-' + item?.code // Usamos este campo solo para búsqueda con el código del padre
    };
  });

  const newOptionsPayConceptAmex = optionPayConcept?.Specific_amex?.map((item) => {
    return {
      key: item?.id_spent,
      code: item?.code,
      value: item?.id_spent,
      label: item?.code + ' - ' + item?.general_concept,
      searchLabel: '0002-' + item?.code
    };
  });

  const dataPaymets = [
    {
      title: '0001 - Caja Chica',
      value: '0001',
      disabled: true,
      children: newOptionsPayConcept
    },
    {
      title: '0002 - Amex',
      value: '0002',
      disabled: true,
      children: newOptionsPayConceptAmex
    }
  ];

  return (
    <>
      <div className="title-content-between align-items-center">
        <h1 className="title-arrow">
          <ArrowLeftOutlined className="arrow-size" onClick={() => navigate('/reporte-general')} />
          CATÁLOGO CONCEPTOS
        </h1>

        <Dropdown
          menu={{
            items
          }}
          trigger={['click']}
          className="mt-catalog"
          placement="bottomLeft">
          <Boton color="primary" variant="outlined">
            Agregar Concepto
            <DownOutlined />
          </Boton>
        </Dropdown>
      </div>

      <Radio.Group
        buttonStyle="solid"
        className="mt-3 mb-3"
        onChange={handleOptionPayment}
        defaultValue="cc">
        <Radio.Button value="cc">Caja Chica</Radio.Button>
        <Radio.Button value="amex">American Express</Radio.Button>
      </Radio.Group>

      {optionPayment === 'cc' ? (
        <TablaCatalogo data={conceptoCajaChica} loading={loading} setLoading={setLoading} />
      ) : optionPayment === 'amex' ? (
        <TablaCatalogo data={conceptoAmex} loading={loading} setLoading={setLoading} />
      ) : null}

      <Modal
        title="AGREGAR CONCEPTO GENERAL"
        centered
        open={isModalOpenGeneral}
        onCancel={showModalGeneral}
        onOk={handleAddConceptGeneral}>
        <Form
          className="mt-4"
          labelCol={{
            span: 10
          }}
          labelAlign="left"
          wrapperCol={{
            span: 14
          }}>
          <Form.Item label="Nombre">
            <Input
              type="text"
              placeholder="Nombre del concepto"
              value={companyNameGeneral}
              onChange={(e) => setCompanyNameGeneral(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Método de pago">
            <Select
              options={OptionPayment}
              placeholder="Seleccione una opción"
              value={selectedSpendingGeneral}
              onChange={(value) => setSelectedSpendingGeneral(value)}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="AGREGAR CONCEPTO ESPECÍFICO"
        centered
        open={isModalOpen}
        onCancel={showModal}
        onOk={handleAddConceptEspecifico}>
        <Form
          className="mt-4"
          labelCol={{
            span: 10
          }}
          labelAlign="left"
          wrapperCol={{
            span: 14
          }}>
          <Form.Item label="Nombre del Concepto">
            <Input
              type="text"
              placeholder="Nombre"
              onChange={(e) => setSpecificConcept(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Concepto General">
            <TreeSelect
              placeholder="Selecciona el concepto"
              showSearch
              allowClear
              treeDefaultExpandAll={false}
              value={val}
              onChange={onChangeselec}
              treeData={dataPaymets}
              filterTreeNode={(inputValue, treeNode) => {
                // Convertimos el input y los labels a minúsculas para una búsqueda insensible a mayúsculas
                const label = (treeNode.label || treeNode.title)?.toLowerCase() || '';
                const searchLabel = (treeNode.searchLabel || '')?.toLowerCase(); // Para búsqueda combinada
                // Retornamos true si el inputValue coincide con el label visible o con el searchLabel (que incluye combinación)
                return (
                  label.includes(inputValue.toLowerCase()) ||
                  searchLabel.includes(inputValue.toLowerCase())
                );
              }}
            />
          </Form.Item>
          {/* <Form.Item label="Categoría">
            <Select
              options={OptionCategory}
              placeholder="Seleccione una opción"
              onChange={(value) => setCategory(value)}
            />
          </Form.Item>
          <Form.Item label="Tipo de Gasto">
            <Select
              options={OptionGCI}
              placeholder="Seleccione una opción"
              onChange={(value) => setTypeOfExpense(value)}
            />
          </Form.Item>
          <Form.Item label="Tipo de Adquisición">
            <Select
              options={OptionAcquisition}
              placeholder="Seleccione una opción"
              onChange={(value) => setTypeOfAcquisition(value)}
            />
          </Form.Item> */}
        </Form>
      </Modal>
    </>
  );
};
export default HistorialConceptos;
