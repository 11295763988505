import React, { useEffect, useState } from 'react';
import { DatePicker, message } from 'antd';
import { SearchOutlined, ClearOutlined } from '@ant-design/icons';
import es from 'antd/lib/date-picker/locale/es_ES';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import TablaSolicitudesCompra from '../../Components/Organisms/TablaSolicitudesCompra';
import Boton from '../Atoms/Boton';

export default function FilterRequest({
  startOfMonth,
  endOfMonth,
  setFechainial,
  setFechafinal,
  handleDating,
  isButton,
  setFilterActive,
  setDataFilterMonth,
  filterActive,
  dataFilterMonth,
  dataRequests,
  loading,
  unnamed
}) {
  startOfMonth = dayjs().startOf('month');
  endOfMonth = dayjs().endOf('month');
  const { RangePicker } = DatePicker;
  const [isReady, setIsReady] = useState(false);

  const fechainicial = localStorage && localStorage?.getItem('dateinitial');
  const fechafinal = localStorage && localStorage?.getItem('datefinal');

  useEffect(() => {
    if (fechainicial && fechafinal) {
      setFechainial(fechainicial);
      setFechafinal(fechafinal);
      setIsReady(true);
    } else {
      setIsReady(false);
    }
  }, []);

  useEffect(() => {
    if (isReady) {
      handleDating();
    }
  }, [isReady]);

  return (
    <>
      <div className="flex-filter align-items-center mt-3">
        <p className="mr-button mb-button">Solicitudes mostradas de: </p>
        <div className="flex-row align-items-center">
          <RangePicker
            format="DD-MM-YYYY"
            placeholder={['Fecha de inicio', 'Fecha de fin']}
            locale={es}
            defaultValue={
              fechainicial && fechafinal
                ? [dayjs(fechainicial), dayjs(fechafinal)]
                : [startOfMonth, endOfMonth]
            }
            onChange={(dates) => {
              if (dates) {
                setFechainial(dates[0].format('YYYY-MM-DD'));
                setFechafinal(dates[1].format('YYYY-MM-DD'));
                localStorage.setItem('dateinitial', dates[0].format('YYYY-MM-DD'));
                localStorage.setItem('datefinal', dates[1].format('YYYY-MM-DD'));
              }
            }}
          />
          <Boton
            color="primary"
            variant="solid"
            shape="circle"
            className="ml-2"
            disabled={isButton}
            onClick={() => {
              handleDating();
            }}
            icon={<SearchOutlined />}
          />
          <Boton
            color="danger"
            variant="outlined"
            shape="circle"
            className="ml-2"
            disabled={isButton}
            onClick={() => {
              setFilterActive(false);
              setDataFilterMonth([]);
              message.success('Filtros eliminados con éxito');
              localStorage.removeItem('dateinitial');
              localStorage.removeItem('datefinal');
            }}
            icon={<ClearOutlined />}
          />
        </div>
      </div>
      <TablaSolicitudesCompra
        data={filterActive ? dataFilterMonth : dataRequests}
        loading={loading}
        unnamed={unnamed}
      />
    </>
  );
}

FilterRequest.propTypes = {
  handleDating: PropTypes.func,
  isButton: PropTypes.bool,
  setFilterActive: PropTypes.func,
  setDataFilterMonth: PropTypes.func,
  filterActive: PropTypes.bool,
  dataFilterMonth: PropTypes.array,
  dataRequests: PropTypes.array,
  loading: PropTypes.bool,
  startOfMonth: PropTypes.object,
  endOfMonth: PropTypes.object,
  setFechainial: PropTypes.func,
  setFechafinal: PropTypes.func,
  unnamed: PropTypes.bool
};
