import React from 'react';
import { Document, Text, View, PDFDownloadLink, Font, Page, Image } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import Boton from '../Atoms/Boton';
import { FilePdfOutlined } from '@ant-design/icons';
import imageFooter from '../../Assets/Images/pdf_footer.png';
import imageHeader from '../../Assets/Images/pdf_header.png';
import montserratSemiBold from '../../Assets/font/Montserrat-SemiBold.ttf';
import montserratBold from '../../Assets/font/Montserrat-Bold.ttf';
import montserratMedium from '../../Assets/font/Montserrat-Medium.ttf';

Font.register({
  family: 'Montserrat-SemiBold',
  src: montserratSemiBold
});

Font.register({
  family: 'Montserrat-Bold',
  src: montserratBold
});

Font.register({
  family: 'Montserrat-Medium',
  src: montserratMedium
});

export const PDFReported = ({ info, total, userRole }) => {
  const styles = {
    page: {
      position: 'relative',
      width: '100%',
      height: '100%',
      zIndex: 1,
      fontFamily: 'Montserrat-Medium',
      fontSize: 14
    },
    titleHeader: {
      fontSize: 23,
      textAlign: 'center',
      fontWeight: 600,
      textTransform: 'capitalize',
      marginTop: 90,
      color: '#1677FF',
      fontFamily: 'Montserrat-Bold'
    },
    padding30: {
      padding: 30
    },
    itemContainer: {
      margin: 10,
      padding: 10,
      border: '1px solid #112a5a',
      borderRadius: 10
    },
    conceptEspecific: {
      color: '#0f4abe',
      marginBottom: 10
    },
    totalText: {
      marginTop: 10,
      flexDirection: 'row',
      justifyContent: 'flex-end'
    },
    pageNumber: {
      position: 'absolute',
      bottom: 20,
      right: 0,
      left: 0,
      textAlign: 'center',
      color: '#112a5a'
    },
    textBlue: {
      color: '#2893ff'
    },
    textPurple: {
      color: '#531dab'
    },
    textMagenta: {
      color: '#c41d7f'
    },
    mLeft: {
      marginLeft: 10
    },
    totalRequest: {
      flexDirection: 'row',
      marginBottom: 10
    },
    containerTotal: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 15
    },
    totalGlobal: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      width: '100%',
      marginTop: 30
    },
    imgFooter: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      zIndex: -1
    },
    imgHeader: {
      position: 'absolute',
      padding: 20,
      top: 0,
      left: 0,
      right: 0,
      width: '100%',
      zIndex: -1
    },
    flexRow: {
      flexDirection: 'row'
    },
    flexRowBetween: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 10
    },
    fontSemiBold: {
      fontFamily: 'Montserrat-SemiBold'
    }
  };

  const currentDate = new Date();

  const date = `${currentDate.getDate()}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`;

  const MyDocument = (
    <Document>
      {info
        ?.sort((a, b) => {
          if (a.amex_or_cajachica === 'Caja chica' && b.amex_or_cajachica === 'Amex') return -1;
          if (a.amex_or_cajachica === 'Amex' && b.amex_or_cajachica === 'Caja chica') return 1;
          return 0;
        })
        .map((item, pageIndex) => (
          <Page style={styles.page} key={pageIndex}>
            <Image style={styles.imgHeader} src={imageHeader} />
            <Image style={styles.imgFooter} src={imageFooter} />
            <Text style={styles.titleHeader}>Reporte general por conceptos</Text>
            <View style={styles.padding30}>
              <View style={styles.flexRowBetween}>
                {['administrator', 'Super_Admin'].includes(userRole) && (
                  <Text style={styles.totalPayMeth}>Método de pago: {item.amex_or_cajachica}</Text>
                )}
                <Text>Concepto General: {item.concepto_general}</Text>
              </View>
              {item.specific?.map((item2, subIndex) => (
                <View key={`item2-${pageIndex}-${subIndex}`} style={styles.itemContainer}>
                  <View style={styles.conceptEspecific}>
                    <Text>Concepto: {item2.concepto_es}</Text>
                  </View>
                  {item2.solicitudes?.map((item3, innerIndex) => (
                    <View key={`item3-${innerIndex}`} style={styles.totalRequest}>
                      <Text style={styles.textBlue}>
                        GTO-{item3.id_sol} = {''}
                      </Text>
                      <Text style={styles.totalSol}>{item3.total_sol}</Text>
                    </View>
                  ))}
                  <View style={styles.totalText}>
                    <Text>Total: {item2.total_es}</Text>
                  </View>
                </View>
              ))}
            </View>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
            />
          </Page>
        ))}
      <Page style={styles.page}>
        <Image style={styles.imgHeader} src={imageHeader} />
        <Image style={styles.imgFooter} src={imageFooter} />
        <Text style={styles.titleHeader}>Total general</Text>
        <View style={styles.padding30} key="total">
          {info
            ?.sort((a, b) => {
              if (a.amex_or_cajachica === 'Caja chica' && b.amex_or_cajachica === 'Amex') return -1;
              if (a.amex_or_cajachica === 'Amex' && b.amex_or_cajachica === 'Caja chica') return 1;
              return 0;
            })
            .map((item) => {
              const met = item.amex_or_cajachica;
              return item.specific?.map((item2) => (
                <View style={styles.containerTotal} key={item2.key}>
                  <View style={styles.flexRow}>
                    <Text style={styles.fontSemiBold}>{item2.concepto_es}</Text>
                    {['administrator', 'Super_Admin'].includes(userRole) && (
                      <Text
                        style={[
                          styles.mLeft,
                          met === 'Amex'
                            ? styles.textMagenta
                            : met === 'Caja chica'
                            ? styles.textPurple
                            : null
                        ]}>
                        ({met})
                      </Text>
                    )}
                  </View>
                  <Text>{item2.total_es}</Text>
                </View>
              ));
            })}
          <View style={styles.totalGlobal}>
            <Text>Total: {total}</Text>
          </View>
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        />
      </Page>
    </Document>
  );

  return (
    <div>
      <PDFDownloadLink document={MyDocument} fileName={`Reporte-Catálogo-${date}.pdf`}>
        {({ loading }) =>
          loading && loading ? (
            <Boton icon={<FilePdfOutlined />} color="danger" variant="outlined" loading={loading}>
              Descargar PDF
            </Boton>
          ) : (
            <Boton icon={<FilePdfOutlined />} color="danger" variant="outlined">
              Descargar PDF
            </Boton>
          )
        }
      </PDFDownloadLink>
    </div>
  );
};

PDFReported.propTypes = {
  info: PropTypes.array,
  total: PropTypes.string,
  userRole: PropTypes.string
};
