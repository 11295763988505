import React, { useLayoutEffect } from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App.routes';
import './Assets/Styles/Global.css';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ConfigProvider } from 'antd';
import { useLocation } from 'react-router-dom';
import GlobalVariables from './Context/GlobalContext';

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Wrapper>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Montserrat, sans-serif'
          }
        }}>
        <GlobalVariables>
          <App />
        </GlobalVariables>
      </ConfigProvider>
    </Wrapper>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
