import { Input, Space, Table, Tag } from 'antd';
import React, { useState, useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import localeObjectTable from '../../Utils/localeObjectTable';
import PropTypes from 'prop-types';
import currencyFormatter from '../../Utils/currencyFormatter';
import { DatePicker } from 'antd';
import moment from 'moment';
import es from 'antd/lib/date-picker/locale/es_ES';
import Boton from '../Atoms/Boton';
import { Target } from '../Molecules/Target';
// import { BotonExcel } from '../Atoms/BotonExcel';

const TablaReporteSolicitudes = ({ data, loading, userRole, loadingCard, loadingCard2 }) => {
  const { RangePicker } = DatePicker;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [pageSize, setPageSize] = useState(15);

  const infoRed =
    data?.SolicitudesAmexCC ||
    data?.SolicitudesCC ||
    data?.SolicitudesAmexBH ||
    data?.SolicitudesAmexPL;

  const newDataSOL = infoRed
    ?.map((item) => {
      return {
        key: item?.id,
        id: item?.id,
        user_name: item?.name_user,
        company: item?.company,
        status: item?.status,
        type_status: item?.type_status,
        total: item.total,
        created_at: item?.created_at,
        spent: item?.spent,
        payment: item?.caja_chica_or_amex,
        approved_by: item?.approved_by ? item?.approved_by : 'PENDIENTE',
        admin_approved: item?.admin_approved ? item?.admin_approved : 'PENDIENTE'
      };
    })
    .reverse();

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: '0 8px 8px 8px'
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Boton
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
          className="button-close-search-filter-table">
          X
        </Boton>
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Space>
          <Boton
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small">
            Buscar
          </Boton>
          <Boton onClick={() => clearFilters && handleReset(clearFilters)} size="small" danger>
            Limpiar
          </Boton>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  });

  const renderApprovalTags = (approvedBy, adminApproved) => {
    return (
      <div>
        {approvedBy && (
          <Tag color={approvedBy === 'PENDIENTE' ? 'blue' : 'success'}>
            JEFE DIRECTO {'-> '}
            <strong>{approvedBy}</strong>
          </Tag>
        )}
        {adminApproved && (
          <Tag color={adminApproved === 'PENDIENTE' ? 'blue' : 'success'} className="mt-3">
            ADMINISTRADOR {'-> '}
            <strong>{adminApproved}</strong>
          </Tag>
        )}
      </div>
    );
  };

  const tablaReporte = [
    {
      title: 'ID SOLICITUD',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      ...getColumnSearchProps('id'),
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['ascend', 'descend'],
      render: (text) => (
        <a href={`/detalle-soliciutd-${text}`} target="_blank" rel="noreferrer noopener">
          GTO-{text}
        </a>
      )
    },
    {
      title: 'SOLICITANTE',
      dataIndex: 'user_name',
      key: 'user_name',
      ...getColumnSearchProps('user_name'),
      sorter: (a, b) => a.user_name.localeCompare(b.user_name),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'EMPRESA',
      dataIndex: 'company',
      key: 'company',
      filters: [
        {
          text: 'PROMO LIFE',
          value: 'Promo life'
        },
        {
          text: 'BH TRADE MARKET',
          value: 'BH Trademarket'
        }
      ],
      onFilter: (value, record) => record.company.indexOf(value) === 0,
      sorter: (a, b) => a.company.length - b.company.length,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'ESTADO',
      dataIndex: 'status',
      key: 'status',
      filters: [
        {
          text: 'EN PROCESO',
          value: 'En proceso'
        },
        {
          text: 'ADQUISICIÓN',
          value: 'Compra'
        },
        {
          text: 'ENTREGADO',
          value: 'Entregado'
        },
        {
          text: 'RECIBIDO',
          value: 'Recibido'
        },
        {
          text: 'DEVOLUCIÓN',
          value: 'Devolución'
        }
      ],
      onFilter: (value, record) => record.status === value,
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['ascend', 'descend'],
      render: (text) => {
        switch (text) {
          case 'En proceso':
            return <Tag color="geekblue">En Proceso</Tag>;
          case 'Compra':
            return <Tag color="purple">Adquisición</Tag>;
          case 'Entregado':
            return <Tag color="gold">Entregado</Tag>;
          case 'Recibido':
            return <Tag color="success">Recibido</Tag>;
          case 'Devolución':
            return <Tag color="error">Devolución</Tag>;
          default:
            return <Tag color="default">ERROR</Tag>;
        }
      }
    },
    {
      title: 'SUB-ESTADO',
      dataIndex: 'type_status',
      key: 'type_status',
      filters: [
        {
          text: 'NORMAL',
          value: 'normal'
        },
        {
          text: 'CANCELADO',
          value: 'cancelado'
        },
        {
          text: 'EN PROCESO',
          value: 'en proceso'
        },
        {
          text: 'RECHAZADA',
          value: 'rechazada'
        }
      ],
      onFilter: (value, record) => record.type_status === value,
      sorter: (a, b) => a.type_status.localeCompare(b.type_status),
      sortDirections: ['ascend', 'descend'],
      render: (text) => {
        switch (text) {
          case 'normal':
            return <Tag color="green-inverse">Normal</Tag>;
          case 'cancelado':
            return <Tag color="red-inverse">Cancelado</Tag>;
          case 'rechazada':
            return <Tag color="magenta-inverse">Rechazada</Tag>;
          case 'en proceso':
            return <Tag color="blue-inverse">En Proceso</Tag>;
          default:
            return <Tag color="default">ERROR</Tag>;
        }
      }
    },
    {
      title: 'TOTAL',
      dataIndex: 'total',
      key: 'total',
      ...getColumnSearchProps('total'),
      sorter: (a, b) => a.total - b.total,
      sortDirections: ['ascend', 'descend'],
      render: (text) => currencyFormatter(text)
    },
    {
      title: 'FECHA DE CREACIÓN',
      dataIndex: 'created_at',
      key: 'created_at',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
          style={{
            padding: '0 8px 8px 8px'
          }}
          onKeyDown={(e) => e.stopPropagation()}>
          <Boton
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            className="button-close-search-filter-table">
            X
          </Boton>
          <RangePicker
            locale={es}
            placeholder={['Fecha inicial', 'Fecha final']}
            value={selectedKeys[0]}
            onChange={(dates) => {
              setSelectedKeys([dates]);
            }}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Boton
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}>
            Buscar
          </Boton>
          <Boton onClick={clearFilters} size="small" danger>
            Limpiar
          </Boton>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? '#1890ff' : undefined
          }}
        />
      ),
      onFilter: (value, record) => {
        if (!value) return true;
        const start = value[0].startOf('day').toDate(); // Convertir a objeto Date
        const end = value[1].endOf('day').toDate(); // Convertir a objeto Date
        const created = moment(record.created_at, 'DD-MM-YYYY').toDate(); // Convertir a objeto Date
        return created >= start && created <= end;
      },
      sorter: (a, b) => a.created_at - b.created_at,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'CONCEPTO',
      dataIndex: 'spent',
      key: 'spent',
      ...getColumnSearchProps('spent'),
      sorter: (a, b) => a.spent.localeCompare(b.spent),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'PAGO',
      dataIndex: 'payment',
      key: 'payment',
      filters: [
        {
          text: 'Amex',
          value: 'Amex'
        },
        {
          text: 'Caja chica',
          value: 'Caja chica'
        }
      ],
      onFilter: (value, record) => record.payment === value,
      sorter: (a, b) => a.payment.localeCompare(b.payment),
      sortDirections: ['ascend', 'descend'],
      render: (text) => {
        switch (text) {
          case 'Amex':
            return <Tag color="purple">Amex</Tag>;
          case 'Caja chica':
            return <Tag color="magenta">Caja chica</Tag>;
          default:
            return <Tag color="default">ERROR</Tag>;
        }
      }
    },
    {
      title: 'AUTORIZACIÓN',
      dataIndex: 'approval',
      key: 'approval',
      render: (text, record) => renderApprovalTags(record.approved_by, record.admin_approved)
    }
  ].filter(Boolean);

  return (
    <>
      <div className="flex-row overflow-x mb-3 mt-4 card-class title-card">
        {['administrator', 'Super_Admin', 'caja_chica'].includes(userRole) && (
          <>
            <Target
              color={'description-green'}
              loading={loading || loadingCard || loadingCard2}
              titulo={'Total Caja Chica'}
              total={data?.AvailableBudget || '0'}
            />
            <Target
              color={'ml-3 description-red'}
              loading={loading}
              titulo={'Gastado Caja Chica'}
              total={data?.MonthlyExpenses || '0'}
            />
          </>
        )}
        {['administrator', 'Super_Admin'].includes(userRole) && (
          <Target
            color={'ml-3 description-red'}
            loading={loading}
            titulo={'Gastado Amex'}
            total={data?.total_spent_amex || '0'}
          />
        )}
        {['administrator', 'Super_Admin', 'adquisiciones_pl'].includes(userRole) && (
          <Target
            color={'ml-3 description-pl'}
            loading={loading}
            titulo={'Gastado Amex PL'}
            total={data?.total_spent_amex_pl || '0'}
          />
        )}
        {['administrator', 'Super_Admin', 'adquisiciones_bh'].includes(userRole) && (
          <Target
            color={'ml-3 description-bh'}
            loading={loading}
            titulo={'Gastado Amex BH'}
            total={data?.total_spent_amex_bh || '0'}
          />
        )}
      </div>

      <div className="flex justify-content-end mb-4">
        {/* <BotonExcel
          tableId="tabla-reporte-solicitudes"
          filename="Reporte Solicitudes"
          sheetName="Reporte Solicitudes"
        /> */}
      </div>

      <Table
        loading={loading}
        columns={tablaReporte}
        dataSource={newDataSOL}
        pagination={{
          position: ['bottomCenter'],
          pageSize: pageSize,
          showSizeChanger: false,
          total: data.total,
          showTotal: (total, range) =>
            `Mostrando solicitudes del ${range[0]} al ${range[1]} de un total de ${total}`
        }}
        className="mt-4 mb-4"
        locale={localeObjectTable}
        scroll={{
          x: true
        }}
      />

      <div className="align-items-center flex justify-content-center">
        <span>SG por página:</span>
        <Input
          className="ml-2 width-10"
          size="medium"
          placeholder="Registros por página"
          type="number"
          defaultValue={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        />
        <Boton
          type="link"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}>
          Ir al inicio
        </Boton>
      </div>
    </>
  );
};

TablaReporteSolicitudes.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  userRole: PropTypes.string,
  loadingCard: PropTypes.bool,
  loadingCard2: PropTypes.bool
};

export default TablaReporteSolicitudes;
